<template>
	<div class="pui-form">
		<partida-modals
			:modelName="modelName"
		></partida-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement || showParentModelHeader">
			<notificacion-form-header v-if="showParentModelHeader" :modelPk="modelPk"></notificacion-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
                <v-flex xs12 md12>
					<pui-field-set :title="$t('partida.field.datos')">
						<v-layout wrap>
							<!-- IDPARTIDA -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idpartida-partida`"
									v-model="model.idpartida"
									:label="$t('partida.idpartida')"
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<!-- IDNOTIF -->
							<v-flex xs12 md6 v-if="!showParentModelHeader">
								<pui-select
									id="idnotif"
									attach="idnotif"
									:label="$t('partida.idnotif')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupnotificacion"
									:itemsToSelect="[{ id: model.idnotif }]"
									:modelFormMapping="{ id: 'idnotif' }"
									itemValue="id"
									:itemText="item => `${item.numdoc} - ${item.estado}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>
							<!-- NUMPARTIDA -->
							<v-flex xs12 md1>
								<pui-number-field
									:id="`numpartida-partida`"
									v-model="model.numpartida"
									:label="$t('partida.numpartida')"
									disabled
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- IDTIPOCARGA -->
							<v-flex xs12 md4>
								<pui-select
									id="idtipocarga"
									attach="idtipocarga"
									:label="$t('partida.idtipocarga')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmtipocarga"
									:itemsToSelect="[{ id: model.idtipocarga }]"
									:modelFormMapping="{ id: 'idtipocarga' }"
									itemValue="id"
									:itemText="item => `${item.name} - ${item.codedi}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- IDTIPBULTO -->
							<v-flex xs12 md4>
								<pui-select
									id="idtipbulto"
									attach="idtipbulto"
									:label="$t('partida.idtipbulto')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmtipobulto"
									:itemsToSelect="[{ id: model.idtipbulto }]"
									:modelFormMapping="{ id: 'idtipbulto' }"
									itemValue="id"
									:itemText="item => `${item.name} - ${item.codedi}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-flex>
							<!-- NUMBULTOS -->
							<v-flex xs12 md1>
								<pui-number-field
									:id="`numbultos-partida`"
									v-model="model.numbultos"
									lazy-validation
									:label="$t('partida.numbultos')"
									:disabled="formDisabled"
									toplabel
									required
									integer
									min="0"
									max="99999999"
								></pui-number-field>
							</v-flex>
							<!-- TRACANLIMITADA -->
							<v-flex xs12 md2 align-self-center>
								<pui-checkbox
									:label="$t('partida.tracanlimitada')"
									v-model="model.tracanlimitada"
									true-value="S"
									false-value="N"
									:disabled="formDisabled"
								></pui-checkbox>
							</v-flex>
							<!-- ENVCUMPLEIMDG -->
							<v-flex xs12 md3 align-self-center>
								<pui-checkbox
									:label="$t('partida.envcumpleimdg')"
									v-model="model.envcumpleimdg"
									true-value="S"
									false-value="N"
									:disabled="formDisabled"
								></pui-checkbox>
							</v-flex>
						</v-layout>

						<pui-field-set :title="$t('partida.field.mmpp')">
							<v-layout wrap>
								<!-- IDIMDG -->
								<v-flex xs12 md8>
									<pui-select
										id="idimdg"
										attach="idimdg"
										:label="$t('partida.idimdg')"
										toplabel
										clearable
										required
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmimdg"
										:itemsToSelect="[{ idimdg: model.idimdg }]"
										:modelFormMapping="{ idimdg: 'idimdg' }"
										itemValue="idimdg"
										:itemText="item => `${item.nombre} - ${item.onu} - ${item.codigoclase} - ${item.variacion} - ${item.version}`"
										:order="{ nombre: 'asc' }"
										:emitSelectedItemsEventOnFirstLoad="isCreatingElement"
										:fixedFilter="fixedFilteridimdg"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<!-- NOMTECNICO -->
								<v-flex xs12 md8>
									<pui-text-field
										:id="`nomtecnico-partida`"
										v-model="model.nomtecnico"
										:label="$t('partida.nomtecnico')"
										:disabled="formDisabled"
										toplabel
										maxlength="350"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<!-- ONU -->
								<v-flex xs12 md2>
									<pui-text-field
										:id="`onu-partida`"
										v-model="model.onu"
										:label="$t('partida.onu')"
										:disabled="formDisabled"
										toplabel
										maxlength="4"
									></pui-text-field>
								</v-flex>
								<!-- CLASE -->
								<v-flex xs12 md2>
									<pui-text-field
										:id="`clase-partida`"
										v-model="model.clase"
										:label="$t('partida.clase')"
										:disabled="formDisabled"
										toplabel
										maxlength="7"
									></pui-text-field>
								</v-flex>
								<!-- VARIACION -->
								<v-flex xs12 md2>
									<pui-text-field
										:id="`variacion-partida`"
										v-model="model.variacion"
										:label="$t('partida.variacion')"
										:disabled="formDisabled"
										toplabel
										maxlength="7"
									></pui-text-field>
								</v-flex>
								<!-- VERSION -->
								<v-flex xs12 md2>
									<pui-text-field
										:id="`version-partida`"
										v-model="model.version"
										:label="$t('partida.version')"
										:disabled="formDisabled"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<!-- IDGRUENV -->
								<v-flex xs12 md3>
									<pui-select
										id="idgruenv"
										attach="idgruenv"
										:label="$t('partida.idgruenv')"
										toplabel
										clearable
										reactive
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmgrupoenvasado"
										:itemsToSelect="[{ codigo: model.idgruenv }]"
										:modelFormMapping="{ codigo: 'idgruenv' }"
										itemValue="codigo"
										itemText="descripcion"
										:order="{ descripcion: 'asc' }"
									></pui-select>
								</v-flex>
								<!-- ART15 -->
								<v-flex xs12 md3 align-self-center>
									<pui-checkbox
										:label="$t('partida.art15')"
										v-model="model.art15"
										true-value="S"
										false-value="N"
										:disabled="formDisabled"
									></pui-checkbox>
								</v-flex>
								<v-flex id="art15maestro" xs12 md3 align-self-center v-if="!isCreatingElement" >
									<pui-checkbox
										:label="$t('partida.art15maestro')"
										v-model="model.art15maestro"
										true-value="S"
										false-value="N"
										disabled
									></pui-checkbox>
								</v-flex>
							</v-layout>
						</pui-field-set>

						<pui-field-set :title="$t('partida.field.medidas')">
							<v-layout wrap>
								<!-- PESONETO -->
								<v-flex xs12 md2>
									<pui-number-field
										:id="`pesoneto-partida`"
										v-model="model.pesoneto"
										:label="$t('partida.pesoneto')"
										:disabled="formDisabled"
										required
										toplabel
										decimals="3"
										maxlength="18"
									></pui-number-field>
								</v-flex>
								<!-- PESOBRUTO -->
								<v-flex xs12 md2>
									<pui-number-field
										:id="`pesobruto-partida`"
										v-model="model.pesobruto"
										:label="$t('partida.pesobruto')"
										:disabled="formDisabled"
										toplabel
										decimals="3"
										maxlength="18"
									></pui-number-field>
								</v-flex>
								<!-- CANNETAEXP -->
								<v-flex xs12 md2>
									<pui-number-field
										:id="`cannetaexp-partida`"
										v-model="model.cannetaexp"
										:label="$t('partida.cannetaexp')"
										:disabled="formDisabled"
										:required="requiredCannetaexp"
										toplabel
										decimals="3"
										maxlength="18"
									></pui-number-field>
								</v-flex>
								<!-- INDTRANSPORTE -->
								<v-flex xs12 md2>
									<pui-number-field
										:id="`indtransporte-partida`"
										v-model="model.indtransporte"
										:label="$t('partida.indtransporte')"
										:disabled="formDisabled"
										:required="requiredIndtransporte"
										toplabel
										decimals="3"
										maxlength="18"
									></pui-number-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<!-- PUNTINFLAMACION -->
								<v-flex xs12 md2>
									<pui-text-field
										:id="`puntinflamacion-partida`"
										v-model="model.puntinflamacion"
										:label="$t('partida.puntinflamacion')"
										:disabled="formDisabled"
										:required="requiredPuntinflamacion"
										toplabel
										maxlength="8"
									></pui-text-field>
								</v-flex>
								<!-- LIMEXPLMIN -->
								<v-flex xs12 md2>
									<pui-number-field
										:id="`limexplmin-partida`"
										v-model="model.limexplmin"
										:label="$t('partida.limexplmin')"
										:disabled="formDisabled"
										:required="requiredLimexplmin"
										toplabel
										decimals="3"
										maxlength="18"
									></pui-number-field>
								</v-flex>
								<!-- LIMEXPMAX -->
								<v-flex xs12 md2>
									<pui-number-field
										:id="`limexpmax-partida`"
										v-model="model.limexpmax"
										:label="$t('partida.limexpmax')"
										:disabled="formDisabled"
										:required="requiredLimexplmax"
										toplabel
										decimals="3"
										maxlength="18"
									></pui-number-field>
								</v-flex>
							</v-layout>
						</pui-field-set>

						<pui-field-set :title="$t('partida.field.instrucciones')">
							<v-layout wrap>
								<!-- LUGARESTIBA -->
								<v-flex xs12 md5>
									<pui-text-field
										:id="`lugarestiba-partida`"
										v-model="model.lugarestiba"
										:label="$t('partida.lugarestiba')"
										:disabled="formDisabled"
										:required="requiredLugarestiba"
										toplabel
										maxlength="25"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<!-- INSESPECIALES -->
								<v-flex xs12 md6>
									<pui-text-area
										:id="`insespeciales-partida`"
										v-model="model.insespeciales"
										:label="$t('partida.insespeciales')"
										:disabled="formDisabled"
										maxlength="350"
										toplabel
									></pui-text-area>
								</v-flex>
								<!-- PROCMANIPULACION -->
								<v-flex xs12 md6>
									<pui-text-area
										:id="`procmanipulacion-partida`"
										v-model="model.procmanipulacion"
										:label="$t('partida.procmanipulacion')"
										:disabled="formDisabled"
										maxlength="350"
										toplabel
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<!-- IDINSTMARCA -->
								<v-flex xs12 md6>
									<pui-select
										id="idinstmarca"
										attach="idinstmarca"
										:label="$t('partida.idinstmarca')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupminstmarcaje"
										:itemsToSelect="[{ id: model.idinstmarca }]"
										:modelFormMapping="{ id: 'idinstmarca' }"
										itemValue="id"
										:itemText="item => `${item.name} - ${item.codedi}`"
										:order="{ name: 'asc' }"
									></pui-select>
								</v-flex>
								<!-- MARCAS -->
								<v-flex xs12 md6>
									<pui-text-field
										:id="`marcas-partida`"
										v-model="model.marcas"
										:label="$t('partida.marcas')"
										:disabled="formDisabled"
										toplabel
										maxlength="350"
									></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>

						<v-layout wrap>
							<v-flex xs12 md6>
								<pui-field-set :title="$t('partida.field.origen')">
									<v-layout wrap>
										<v-flex xs12 md12>
											<pui-radio-group
												id="partidaorigenradio"
												v-model="radioorigen.selected"
												:radios="radioorigen.tipos"
												row
											></pui-radio-group>
										</v-flex>
									</v-layout>
									<v-layout wrap v-if="!disableSelectLocalidadOrigen">
										<!-- IDPTOORILOCALIDAD -->
										<v-flex xs12 md12>
											<pui-select
												id="idptoorilocalidad"
												attach="idptoorilocalidad"
												:label="$t('partida.idptoorilocalidad')"
												toplabel
												clearable
												reactive
												:disabled="formDisabled"
												v-model="model"
												modelName="vlupmlocalidad"
												:itemsToSelect="[{ id: model.idptoorilocalidad }]"
												:modelFormMapping="{ id: 'idptoorilocalidad' }"
												itemValue="id"
												:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
												:order="{ name: 'asc' }"
											></pui-select>
										</v-flex>
									</v-layout>
									<v-layout wrap v-if="disableSelectLocalidadOrigen">
										<!-- IDPTOORIPAIS -->
										<v-flex xs12 md6>
											<pui-select
												id="idptooripais"
												attach="idptooripais"
												:label="$t('partida.idptooripais')"
												toplabel
												clearable
												reactive
												:disabled="formDisabled"
												v-model="model"
												modelName="vlupmpais"
												:itemsToSelect="[{ codigo: model.idptooripais }]"
												:modelFormMapping="{ codigo: 'idptooripais' }"
												itemValue="codigo"
												:itemText="item => `${item.descripcion} - ${item.codlocode}`"
												:order="{ descripcion: 'asc' }"
											></pui-select>
										</v-flex>
										<!-- NOMORILOCALIDAD -->
										<v-flex xs12 md6>
											<pui-text-field
												:id="`nomorilocalidad-partida`"
												v-model="model.nomorilocalidad"
												:label="$t('partida.nomorilocalidad')"
												:disabled="formDisabled"
												toplabel
												maxlength="17"
											></pui-text-field>
										</v-flex>
									</v-layout>
								</pui-field-set>
							</v-flex>
							<v-flex xs12 md6>
								<pui-field-set :title="$t('partida.field.destino')">
									<v-layout wrap>
										<v-flex xs12 md12>
											<pui-radio-group
												id="partidadestinoradio"
												v-model="radiodestino.selected"
												:radios="radiodestino.tipos"
												row
											></pui-radio-group>
										</v-flex>
									</v-layout>
									<v-layout wrap v-if="!disableSelectLocalidadDestino">
										<!-- IDPTODESLOCALIDAD -->
										<v-flex xs12 md12>
											<pui-select
												id="idptodeslocalidad"
												attach="idptodeslocalidad"
												:label="$t('partida.idptodeslocalidad')"
												toplabel
												clearable
												reactive
												:disabled="formDisabled"
												v-model="model"
												modelName="vlupmlocalidad"
												:itemsToSelect="[{ id: model.idptodeslocalidad }]"
												:modelFormMapping="{ id: 'idptodeslocalidad' }"
												itemValue="id"
												:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
												:order="{ name: 'asc' }"
											></pui-select>
										</v-flex>
									</v-layout>
									<v-layout wrap v-if="disableSelectLocalidadDestino">
										<!-- IDPTODESPAIS -->
										<v-flex xs12 md6>
											<pui-select
												id="idptodespais"
												attach="idptodespais"
												:label="$t('partida.idptodespais')"
												toplabel
												clearable
												reactive
												:disabled="formDisabled"
												v-model="model"
												modelName="vlupmpais"
												:itemsToSelect="[{ codigo: model.idptodespais }]"
												:modelFormMapping="{ codigo: 'idptodespais' }"
												itemValue="codigo"
												:itemText="item => `${item.descripcion} - ${item.codlocode}`"
												:order="{ descripcion: 'asc' }"
											></pui-select>
										</v-flex>
										<!-- NOMDESLOCALIDAD -->
										<v-flex xs12 md6>
											<pui-text-field
												:id="`nomdeslocalidad-partida`"
												v-model="model.nomdeslocalidad"
												:label="$t('partida.nomdeslocalidad')"
												:disabled="formDisabled"
												toplabel
												maxlength="17"
											></pui-text-field>
										</v-flex>
									</v-layout>
								</pui-field-set>
							</v-flex>
						</v-layout>
						<v-layout wrap >
							<v-flex xs12 md6>
								<pui-field-set :title="$t('partida.field.expedidor')">
									<partida-form-implicado
										:model="model.implicadocz"
										:formDisabled="formDisabled"
										:idpuertolocal="this.idpuertolocal"
										templatename="implicadocz"
									></partida-form-implicado>
								</pui-field-set>
							</v-flex>
							<v-flex xs12 md6>
								<pui-field-set :title="$t('partida.field.destinatario')">
									<partida-form-implicado
										:model="model.implicadorf"
										:formDisabled="formDisabled"
										:idpuertolocal="this.idpuertolocal"
										templatename="implicadorf"
									></partida-form-implicado>
								</pui-field-set>
							</v-flex>
						</v-layout>

						<v-layout wrap v-if="!isCreatingElement">
							<v-flex xs12 md12>
								<pui-field-set :title="$t('partida.field.equipamientos')">
									<v-layout dense>
									<v-flex xs10>
										<pui-quick-edition-datatable
											id="partidaequipamientoquickedit-form"
											:parentModelName="modelName"
											:parentPk="this.pk"
											:parentPkChildFk="{ idpartida: 'idpartida' }"
											:disabled="formDisabled"
											:modelName="this.childModelName"
											formComponentName="partidaequipamientoquickedit-form"
											:parentModel="model"
											formPosition="top"
											showPaginator="false"
										></pui-quick-edition-datatable>
									</v-flex>
									</v-layout>
								</pui-field-set>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<!-- MINI AUDIT -->
                    <pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="saveCustom"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import partidaActions from './PartidaActions';
import partidaModals from './PartidaModals.vue';
import partidaFormImplicado from './PartidaFormImplicado.vue';

export default {
	name: 'partida-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'partida-modals': partidaModals,
		'partidaFormImplicado': partidaFormImplicado
	},
	data() {
		return {
			modelName: 'partida',
			actions: partidaActions.formActions,
			parentModelName: 'notificacion',
			showParentModelHeader: false,
			disableSelectLocalidadOrigen: true,
			disableSelectLocalidadDestino: true,
			childModelName: 'partidaequipamiento',
			radioorigen: {
				selected: null,
				tipos: [
					{
						id: 0,
						label: this.$t('partida.origen.localidad')
					},
					{
						id: 1,
						label: this.$t('partida.origen.pais')
					}
				]
			},
			radiodestino: {
				selected: null,
				tipos: [
					{
						id: 0,
						label: this.$t('partida.origen.localidad')
					},
					{
						id: 1,
						label: this.$t('partida.origen.pais')
					}
				]
			},
			idpuertolocal: 1,
			art15maestro: 'N'

		};
	},
	props: {
	},
	watch: {
		'radioorigen.selected'(value) {
			if (value === 0) {
				this.disableSelectLocalidadOrigen = false;
				this.model.idptooripais = null;
				this.model.ptoorigen = null;
				this.model.nomorilocalidad = null;
			}
			if (value === 1) {
				this.disableSelectLocalidadOrigen = true;
				this.model.idptoorilocalidad = null;
			}
		},
		'radiodestino.selected'(value) {
			if (value === 0) {
				this.disableSelectLocalidadDestino = false;
				this.model.idptodespais = null;
				this.model.ptodestino = null;
				this.model.nomdeslocalidad = null;
			}
			if (value === 1) {
				this.disableSelectLocalidadDestino = true;
				this.model.idptodeslocalidad = null;
			}
		}
	},
	methods: {
		saveCustom(){

			if (this.isCreatingElement){
				this.saveAndUpdate();
			} else {
				this.save();
			}
		},

		afterGetData() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel != null) {
				const pkParent = parentModel.pk;
				this.model.idnotif = pkParent.idnotif;
				this.showParentModelHeader = true;
			}

			if(!this.isCreatingElement || this.model.idnotif != null){
				this.getIdPuertoLocal();
			}

			if(this.model.idptoorilocalidad != null){
				this.radioorigen.selected = 0;
				this.model.idptooripais = null;
				this.model.ptoorigen = null;
				this.model.nomorilocalidad = null;
			} else if(this.model.idptooripais != null){
				this.radioorigen.selected = 1;
				this.model.idptoorilocalidad = null;
			} else {
				this.radioorigen.selected = 0;
			}

			if(this.model.idptodeslocalidad != null){
				this.radiodestino.selected = 0;
				this.model.idptodespais = null;
				this.model.ptodestino = null;
				this.model.nomdeslocalidad = null;
			} else if(this.model.idptodespais != null){
				this.radiodestino.selected = 1;
				this.model.idptodeslocalidad = null;
			} else {
				this.radiodestino.selected = 0;
			}

			this.$set(this.model, 'existenequipamientospartidas', false);

			if (this.storedData && this.storedData.numescent){
				this.$set(this.model, 'numescent', this.storedData.numescent);
			}

		},
		watcherLupidimdg(){
			this.$puiEvents.$on(`onPuiSelect_selectedItems-idimdg`, newValImdg => {
				var lupamodel = newValImdg.model;
				if (!this.$appUtils.isEmpty(lupamodel)) {
					this.model.nomtecnico = lupamodel.nombre;
					this.model.onu = lupamodel.onu;
					this.model.clase = lupamodel.codigoclase;
					this.model.variacion = lupamodel.variacion;
					this.model.version = lupamodel.version;
					this.model.idgruenv = lupamodel.idgruenv;
					this.model.art15maestro = lupamodel.articulo15 !== null && lupamodel.articulo15 === '0' ? 'N' : 'S';
					this.changeColorArt15maestro();


					if(lupamodel.articulo15 === '1'){
						this.model.art15 = 'S';
					}
				}
			});
		},
		watcherLupidptoorilocalidad(){
			this.$puiEvents.$on(`onPuiSelect_selectedItems-idptoorilocalidad`, newVal => {
				var lupamodel = newVal.model;
				if (!this.$appUtils.isEmpty(lupamodel)) {
					if(lupamodel.codlocodepais && lupamodel.codlocode)
						this.model.ptoorigen = lupamodel.codlocodepais + lupamodel.codlocode;
				}
			});
		},
		watcherLupidptodeslocalidad(){
			this.$puiEvents.$on(`onPuiSelect_selectedItems-idptodeslocalidad`, newVal => {
				var lupamodel = newVal.model;
				if (!this.$appUtils.isEmpty(lupamodel)) {
					if(lupamodel.codlocodepais && lupamodel.codlocode)
						this.model.ptodestino = lupamodel.codlocodepais + lupamodel.codlocode;
				}
			});
		},
		getIdPuertoLocal(){
			const params = {
				idnotif: this.model.idnotif,
			};

			const url = this.$store.getters.getModelByName("partida").url.getIdPuertoLocal;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('partida.getpuertolocal.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					this.idpuertolocal = response.data;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		changeColorArt15maestro() {

				setTimeout(function () {
					var art15maestro = document.getElementById("art15maestro");
					if(art15maestro !== null) {
						var firstChild = art15maestro.firstChild;
						var secondChild = firstChild.firstChild;
						var thirdChild = secondChild.firstChild;
						var childLabel = thirdChild.childNodes[1];
						if(this.model.art15 !== this.model.art15maestro) {
							childLabel.style.setProperty("color", "#f11212", "important");
						} else {
							childLabel.style.removeProperty("color");
						}
					}

				}.bind(this), 2000)

		}
	},
	computed: {
		requiredCannetaexp() {
			if(this.model.clase && this.model.clase.startsWith('1')) return true;
			return false;
		},
		requiredPuntinflamacion() {
			if(this.model.clase && this.model.clase.startsWith('1')) return true;
			return false;
		},
		requiredLimexplmin() {
			if(this.model.clase && this.model.clase.startsWith('3')) return true;
			return false;
		},
		requiredLimexplmax() {
			if(this.model.clase && this.model.clase.startsWith('3')) return true;
			return false;
		},
		requiredIndtransporte() {
			if(this.model.clase && this.model.clase.startsWith('7')) return true;
			return false;
		},
		requiredLugarestiba() {
			//comprobar que no existan equipamientosPartida y escala de entrada
			if (!this.isCreatingElement && this.storedData && this.storedData.numescent
				&& !this.model.existenequipamientospartidas) {
				return true;
			}
			return false;
		},
		getNumescent(){
			if (this.storedData && this.storedData.numescent) {
				return this.storedData.numescent;
			}
			return null;
		},
		fixedFilteridimdg() {
			return {
				groups:[
					{
						groupOp: 'and',
						rules: [
							{ field: 'prohibido', op: 'ne', data: 1 }
						]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},

	},
	created() {
	},
	mounted() {
		this.watcherLupidimdg();
		this.watcherLupidptoorilocalidad();
		this.watcherLupidptodeslocalidad();
		this.changeColorArt15maestro();

	}
}


</script>
