<template>
	<v-layout wrap class="pui-form-layout">
		<v-flex xs12 md12>	
			<v-layout wrap>
				<!-- IMPLICADO -->				
				<!-- IDAGENTEDEC -->
				<v-flex xs12 md12>
					<pui-select							
						:id="'agente-implicado-' + this.templatename"				
						attach="idagente"
						:label="$t('notificacion.implicado.idagente')"
						toplabel
						clearable
						:disabled="formDisabled"
						v-model="model"
						modelName="vlupmagente"
						:itemsToSelect="[{ id: model.idagente }]"
						:modelFormMapping="{ id: 'idagente' }"
						itemValue="id"										
						:itemText="item => `${item.nif} - ${item.nombre}`"
						:order="{ nombre: 'asc' }"
					></pui-select>
				</v-flex>				
			</v-layout>			
			<v-layout wrap>
				<!-- NIF -->
				<v-flex xs12 md3>
					<pui-text-field													
						:label="$t('notificacion.implicado.nif')"
						v-model="model.nif"
						:disabled="formDisabled"
						toplabel
					></pui-text-field>
				</v-flex>
				<v-flex xs12 md9>
					<!-- NOMBRE -->
					<pui-text-field													
						:label="$t('notificacion.implicado.nombre')"
						v-model="model.nombre"
						:disabled="formDisabled"
						toplabel
					></pui-text-field>
				</v-flex>				
			</v-layout>
			<pui-field-set :title="$t('notificacion.implicados.contactoemergencia')">
				<v-layout wrap>
					<!-- LUP-MCONTACTO -->
					<v-flex xs12 md12>
						<pui-select							
							:id="'contacto-implicado-' + this.templatename"
							toplabel
							clearable
							:disabled="formDisabled"										
							modelName="vlupmcontacto"										
							:itemText="item => `${item.nombre} - ${item.nif} - ${item.numero}`"
							:order="{ nombre: 'asc' }"
							:fixedFilter="fixedFilterContacto"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.nif')"
							v-model="model.implicadocto.nif"						
							toplabel													
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md6>
						<pui-text-field													
							:label="$t('notificacion.implicados.nombre')"
							v-model="model.implicadocto.contacto"							
							toplabel
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3>
						<pui-text-field													
							:label="$t('notificacion.implicados.telefono')"
							v-model="model.implicadocto.implicadoctocmnc.numero"							
							toplabel
						></pui-text-field>
					</v-flex>
				</v-layout>				
			</pui-field-set>
		</v-flex>
	</v-layout>					
</template>

<script>

export default {
	name: 'partida-form-implicado',
	data() {
		return {
			modelName: 'partida'
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		idpuertolocal: {
			type: Number,
			required: true
		},		
		templatename: {
			type: String,
			required: true
		}
	},	
	computed: {
		fixedFilterContacto() {
			return {
				groups:[
					{
						groupOp: 'and',
						rules: [{ field: 'idpuertolocal', op: 'eq', data: this.idpuertolocal }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		}
	},
	mounted() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-contacto-implicado-${this.templatename}`, newValContacto => {
			var lupamodel = newValContacto.model;
			if (!this.$appUtils.isEmpty(lupamodel)) {
				this.model.implicadocto.nif = lupamodel.nif;
				this.model.implicadocto.contacto = lupamodel.nombre;
				this.model.implicadocto.implicadoctocmnc.numero = lupamodel.numero;
			}
        });
		this.$puiEvents.$on(`onPuiSelect_selectedItems-agente-implicado-${this.templatename}`, newValContacto => {
			var lupamodel = newValContacto.model;
			if (!this.$appUtils.isEmpty(lupamodel)) {
				this.model.nif = lupamodel.nif;
				this.model.nombre = lupamodel.nombre;
			}
        });
    }
}
</script>
